import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import logo from './img/logo.svg';
import facebook from './img/facebook.svg';
import linkedin from './img/linkedin.svg';
import instagram from './img/instagram.svg';
import behance from './img/behance.svg';
import tumblr from './img/tumblr.svg';
// import upwork from './img/upwork.svg';
// "Footer"
// import tikTok from './img/tikTok.svg';

import './Footer.scss';

const Footer = ({ showBackground=true }) => {
  return (
    <footer className={classNames('Footer', { 'show-bg-img': showBackground })}>
      <div className="Footer__list">
        <div className="Footer__item">
          <Link
            to="/"
            title="Logo"
          >
            <img
              className="Footer__logo"
              src={logo}
              alt="SolidOne"
            />
          </Link>
          <p>Software Development Company</p>
        </div>

        <div className="Footer__item">
          <address className="Footer__social">
            <a
              title="facebook"
              href="https://www.facebook.com/SolidOneTeam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt="SolidOne facebook"
              />
            </a>
            <a
              title="linkedin"
              href="https://www.linkedin.com/company/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedin}
                alt="SolidOne linkedin"
              />
            </a>
            <a
              title="Instagram"
              href="https://www.instagram.com/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagram}
                alt="SolidOne instagram"
              />
            </a>
            <a
              title="Behance"
              // href="https://www.instagram.com/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={behance}
                alt="SolidOne behance"
              />
            </a>
            <a
              title="Tumblr"
              // href="https://www.instagram.com/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={tumblr}
                alt="SolidOne tumblr"
              />
            </a>
            {/* <a
              title="upwork"
              href="https://www.upwork.com/ag/solidoneteam"
              target="_blank"
              rel="noreferrer"
            >upwork</a> */}
          </address>
        </div>
      </div>
    </footer>
  );
};

export default Footer;