import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import { MaxLG } from '../../components/Media';

import logo from './img/logo.svg';
import './Header.scss';

function useOutsideAlerter(ref) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document.body.classList.remove('is-lock');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref]);
}

const Header = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const toggleClass = () => {
    document.body.classList.toggle('is-lock');
  }

  const removeClass = () => {
    document.body.classList.remove('is-lock');
  }

  return (
    <header className="Header">
      <div className="Header__wrapper">
        <div className="Header__item">
          <div className="Header__logo">
            <Link
              to="/"
              title="Logo"
            >
              <img
                className="Header__logo-img"
                src={logo}
                alt="SolidOne"
              />
            </Link>
            <p>Software Development Company<mark class="mark-second">.</mark></p>
          </div>
        </div>
        <div className="Header__item" ref={wrapperRef}>
          <MaxLG>
            <button
              className="Header__btn"
              type="button"
              onClick={toggleClass}
            >
              <span />
              <span />
              <span />
            </button>
          </MaxLG>
          <div className="Header__list">
            <nav id="list" className="Header__nav">
              <a
                onClick={removeClass}
                className="Header__nav-link"
                href="#whatWedo"
              >
                About us
              </a>
              <a
                onClick={removeClass}
                className="Header__nav-link"
                href="#solutions"
              >
                Services
              </a>
              <a
                onClick={removeClass}
                className="Header__nav-link"
                href="#contact"
              >
                Solutions
              </a>
              <a
                className="Header__nav-btn"
              >
                Log in
              </a>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
